
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {}
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class TicketsBlock extends Vue {}
