
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import Slider from '../../../fruitpackage/components/Slider.vue';
import CardTall from '@part/elements/CardTall.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Slider,
    CardTall
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class SliderBlock extends Vue {}
