
/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ClockSvg from '@svg/ClockMultiColor.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
  props: ['data', 'type'],
  components: {
    ClockSvg,
  },
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class CardTall extends Vue {}
